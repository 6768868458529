<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="100px">
        <el-form-item label="关键字" class="kw">
          <el-input
            placeholder="请输入"
            v-model="searchData.keywordValue"
            class="input-with-select"
            size="small"
          >
            <el-select
              v-model="searchData.keywordName"
              slot="prepend"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in searchTypeReview"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            style="width: 350px"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单类型" class="order-type">
          <el-select
            v-model="searchData.orderType"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option
              v-for="item in dict_order_type"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐类型" class="order-type">
          <SelectDict
            ref="combo"
            v-model="searchData.goodsCombo"
            width="350px"
            dictCode="goods:combo"
          ></SelectDict>
        </el-form-item>
        <el-form-item label="是否免押">
          <el-select
            v-model="searchData.enableAlipayFundAuth"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option label="是" :value="true"> </el-option>
            <el-option label="否" :value="false"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList()"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-tab">
      <div class="left-tab">
        <span
          @click="activeName = tab.value"
          v-for="tab in reviewStatus"
          :key="tab.value"
        >
          <el-badge
            v-if="tab.value != 0"
            :class="[activeName == tab.value ? 'select-tab' : '']"
            :value="getStatic(tab.value)"
          >
            {{ tab.name }}
          </el-badge>
          <el-badge
            v-else
            :class="[activeName == tab.value ? 'select-tab' : '']"
          >
            {{ tab.name }}
          </el-badge>
        </span>
        <template> </template>
      </div>
      <!-- <el-button v-if="activeName != 2" type="primary" size="mini" @click="downloadExcel">批量分配</el-button>
      <el-button v-else type="primary" size="mini" @click="downloadExcel">批量放弃领单</el-button> -->
    </div>
    <!-- <div class="header-count">
      <div class="total">
        <div></div>
        <div class="right">
          <span>总数：{{ list.length }}</span>
          <span>
            <el-dropdown>
              <el-button size="mini" type="" plain> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-show="activeName != 2">领单</el-dropdown-item>
                <el-dropdown-item v-show="activeName == 2">放弃领单</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div class="selected">
        <i class="el-icon-warning"></i>已选择：<span>{{ tableSelected.length }}</span>
      </div>
    </div> -->
    <tp-table
      @handleSelectionChange="handleSelectionChange"
      :isCheckBox="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import SelectDict from "@/views/components/select-dict.vue";
import { authBtnMixin } from "@/mixins/authBtnMixin";
import {
  searchTypeReview,
  reviewStatus,
  dict_order_type,
} from "@/enum/dict/index.js";
import {
  getReviewListAPI,
  getReviewOrderDrawAPI,
  handleReviewOrderAbandonAPI,
  getReviewOrderStaticAPI,
  hitAPI,
} from "./api";
const columns = [
  {
    label: "用户信息",
    prop: "member",
    minWidth: "200",
    customRender(h, row) {
      return (
        <p>
          <div>姓名：{row["member"]?.["memberName"]}</div>
          <div>手机号：{row["member"]?.["mobile"]}</div>
        </p>
      );
    },
  },
  {
    label: "门店/渠道",
    prop: "StoreChannel",
    minWidth: "200",
    customRender(h, row) {
      return (
        <div>
          <div v-show={row["store"]}>
            <div>门店名称：{row["store"]?.["storeName"]}</div>
            <div>
              门店等级：
              {row["store"]?.["storeGrade"]
                ? `${row["store"]?.["storeGrade"]}级`
                : "-"}
            </div>
            {/* <div>联系电话：{row["store"]?.["legalMobile"]}</div> */}
            <div>门店归属：{row["store"]?.["businessUserName"]}</div>
          </div>
          <div v-show={row["channel"]}>
            <div>渠道名称：{row["channel"]?.["channelName"]}</div>
            <div>渠道归属：{row["channel"]?.["chargeName"]}</div>
          </div>
        </div>
      );
    },
  },
  {
    label: "审核人",
    prop: "reviewUser",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["reviewUser"]?.["userName"]}</p>;
    },
  },
  // {
  //   label: '客服',
  //   prop: 'kfUser',
  //   minWidth: '100',
  //   customRender(h, row) {
  //     return <p>{row['kfUser']?.['userName']}</p>
  //   }
  // },
  {
    label: "订单信息",
    prop: "createTime",
    minWidth: "300",
    customRender(h, row) {
      return (
        <div>
          <div>订单单号：{row["orderNo"]}</div>
          <div>下单时间：{row["createTime"]}</div>
          <div>订单状态：{row["statusName"]}</div>
          <div>
            订单类型：
            <el-tag v-show={row["orderType"] == 1} type="primary" size="mini">
              普通订单
            </el-tag>
            <el-tag v-show={row["orderType"] == 2} type="warning" size="mini">
              门店订单
            </el-tag>
            <el-tag v-show={row["orderType"] == 3} type="success" size="mini">
              卡券订单
            </el-tag>
            <el-tag
              v-show={row["orderType"] == 4}
              color="#ffcccc54"
              style="color:#ff7744;border-color:#ffcccc54"
              size="mini"
            >
              电车订单
            </el-tag>
            <el-tag
              v-show={row["enableAlipayFundAuth"] == true}
              type="danger"
              size="mini"
              style="margin-left: 5px"
            >
              免押
            </el-tag>
          </div>

          <div>下单方式：{row["clientTypeName"]}</div>
          <div>
            套餐类型：
            <el-tag type="primary" size="mini">
              {this.filterCombo(row["goodsCombo"])}
            </el-tag>
          </div>
        </div>
      );
    },
  },
  {
    label: "商品信息",
    prop: "orderGoods",
    minWidth: "180",
    customRender(h, row) {
      return <div>{row["orderGoods"]?.["skuTitle"]}</div>;
    },
  },
  {
    label: "价格",
    prop: "rentPrice",
    minWidth: "220",
    align: "center",
    customRender(h, row) {
      return (
        <div>
          <div class="price" v-show={row["goodsCombo"] == "buyoutReturnAtTerm"}>
            <div class="item">
              <span>总租金(期数)：</span>
              <span>
                {row["rentPrice"]} ({row["billPeriodNum"]}期)
              </span>
            </div>
            <div class="item">
              <span>买断金：</span>
              <span> {row["buyoutPrice"]}</span>
            </div>
            <div class="item">
              <span>溢价金：</span>
              <span>{row["overflowPrice"]}</span>
            </div>
            <div class="item">
              <span>保险金：</span>
              <span> {row["insurancePrice"]}</span>
            </div>
            <div class="item">
              <span>平台服务费：</span>
              <span> {row["servicePrice"]}</span>
            </div>
          </div>
          <div class="price" v-show={row["goodsCombo"] == "payAfterUse"}>
            <div class="item">
              <span>金额：</span>
              <span>{row["rentPrice"]}</span>
            </div>
            <div class="item">
              <span>后付天数：</span>
              <span> {row["delayDayNum"]}</span>
            </div>
          </div>
          <div class="price" v-show={row["goodsCombo"] == "payAtOnce"}>
            <div class="item">
              <span>金额：</span>
              <span>{row["rentPrice"]}</span>
            </div>
          </div>
        </div>
      );
    },
  },
  // {
  //   label: '时间',
  //   prop: 'payTime',
  //   minWidth: '230',
  //   customRender(h, row) {
  //     return (
  //       <div>
  //         <div>支付时间：{row['payTime']}</div>
  //         <div>开始时间：{row['startRentDate']}</div>
  //         <div>结束时间：{row['endRentDate']}</div>
  //       </div>
  //     )
  //   }
  // },
  {
    label: "发货信息",
    prop: "orderAddress",
    minWidth: "240",
    customRender(h, row) {
      return (
        <div>
          <div>
            收货信息：{row["orderAddress"]?.["contactName"]}{" "}
            {row["orderAddress"]?.["contactMobile"]}
          </div>
          <div>
            收货地址：{row["orderAddress"]?.["province"]}
            {row["orderAddress"]?.["city"]}
            {row["orderAddress"]?.["district"]}
            {row["orderAddress"]?.["detail"]}
          </div>
        </div>
      );
    },
  },
  // {
  //   label: '商户',
  //   prop: 'merchant',
  //   minWidth: '150',
  //   customRender(h, row) {
  //     return <p>{row['merchant']?.['merchantName']}</p>
  //   }
  // },
  {
    label: "操作",
    minWidth: "150",
    fixed: "right",
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.review_list_draw)}
            v-show={!row["reviewUser"]?.["userName"] && this.activeName != 2}
            underline={false}
            type="primary"
            onClick={() => this.getReviewOrderDraw(row["orderNo"])}
          >
            领单
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.review_list_draw_done)}
            style="margin:0 10px;"
            v-show={this.activeName == 2}
            underline={false}
            type="primary"
            onClick={() => this.goReview(row)}
          >
            审核
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.review_list_draw_cancel)}
            v-show={this.activeName == 2}
            underline={false}
            type="primary"
            onClick={() => this.handleReviewOrderAbandon(row["orderNo"])}
          >
            放弃领单
          </el-link>
        </div>
      );
    },
  },
];
export default {
  name: "List",
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        timeSlot: [],
        keywordName: "",
        keywordValue: "",
        orderType: "",
        goodsCombo: "",
        enableAlipayFundAuth: "",
      },
      searchTypeReview,
      dict_order_type,
      activeName: 0,
      tableSelected: [],
      reviewNum: 0,
      reviewStatus,
      staticData: {},
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  computed: {},
  mounted() {
    window["getBpageList"] = () => {
      this.getList();
    };
  },
  watch: {
    activeName: {
      immediate: true,
      handler(newV, oldV) {
        this.getList();
      },
    },
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
  },
  methods: {
    async getList() {
      const {
        timeSlot,
        keywordName,
        keywordValue,
        orderType,
        goodsCombo,
        enableAlipayFundAuth,
      } = this.searchData;
      const { activeName } = this;
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        orderType,
        goodsCombo,
        enableAlipayFundAuth,
      };
      params.reviewTab = activeName;
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0];
        params.rightCreateDate = timeSlot[1];
      }
      if (keywordName) params[keywordName] = keywordValue;
      const res = await getReviewListAPI(params);
      this.list = res.list || [];
      this.total = res.total || 0;
      this.getReviewOrderStatic();
    },
    // 领单
    getReviewOrderDraw(orderNo) {
      this.$confirm("此操作将领取该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getReviewOrderDrawAPI(orderNo).then(() => {
            this.getList();
            this.$message.success("领取成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 放弃领单
    handleReviewOrderAbandon(orderNo) {
      this.$confirm("此操作将放弃该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          handleReviewOrderAbandonAPI(orderNo).then(() => {
            this.getList();
            this.$message.success("放弃成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    downloadExcel() {},
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData);
      if (this.activeName == 0) {
        return this.getList();
      }
      this.activeName = 0;
    },
    handleSelectionChange(val) {
      this.tableSelected = val;
    },
    async getReviewOrderStatic() {
      this.staticData = await getReviewOrderStaticAPI();
    },
    getStatic(value) {
      const { staticData } = this;
      let num = 0;
      switch (value) {
        case "1":
          num = staticData.waitReviewNum || 0;
          break;
        case "2":
          num = staticData.inReviewNum || 0;
          break;

        default:
          break;
      }
      return num;
    },
    // 套餐类型
    filterCombo(str) {
      let name = "";
      const list = this.$refs.combo.list;
      const res = list.find((item) => item.value == str);
      if (res) {
        name = res.name;
      }
      return name;
    },
    async goReview(row) {
      await hitAPI(row["orderNo"]);
      let link = this.$router.resolve({
        name: `ReviewDetail`,
        params: { id: row["orderNo"] },
        query: { memberId: row["member"]?.["id"], orderType: row["orderType"] },
      });
      window.open(link.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 350px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      .kw {
        .el-select .el-input {
          width: 100px;
        }
      }

      .input-with-select {
        vertical-align: middle;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
      .order-type {
        .el-select,
        .el-input {
          width: 350px;
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dcdfe6;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    .left-tab {
      & > span {
        cursor: pointer;
        margin-right: 30px;
      }
      .select-tab {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }

      .el-badge {
        .el-badge__content.is-fixed {
          top: 7px;
          right: 5px;
        }
        .el-badge__content {
          height: 20px;
        }
      }
    }
  }
  .header-count {
    margin: 20px 0;
    .total {
      display: flex;
      justify-content: space-between;
      .right {
        & > span {
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
    .selected {
      margin-top: 10px;
      padding: 0 10px;
      background-color: #e6f7ff;
      border: 1px solid #a3dcff;
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      i {
        color: #409eff;
        margin-right: 10px;
        font-size: 14px;
      }
      span {
        color: #409eff;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  .price {
    .item {
      min-width: 200px;
      display: flex;
      span {
        &:first-child {
          min-width: 100px;

          text-align: right;
        }
        &:last-child {
          min-width: 100px;
          text-align: left;
        }
      }
    }
  }
}
</style>
